import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyCustomerIndex, MyLoweIndex } from '@/api/ceshi_xiugai/whole';
import { getProductPageList } from '@/api/ceshi_xiugai/product';
export default {
  name: 'DisplaySettingser',
  props: {
    displayArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    displayValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    list_type: {
      type: Number,
      required: true
    },
    resultList: {}
  },
  data: function data() {
    return {
      value: JSON.parse(JSON.stringify(this.displayValue)),
      name: 'names',
      dialogTableVisible: false,
      draging: null,
      fullscreenLoading: false,
      page: 1,
      pagesize: 10,
      disp: [
      //全部客户、创建的客户、共享的客户、未回访的客户、操作下级-全部数据
      '序号', '置顶', '客户名称', '电话号码', '学员来源', '学员级别', '跟进', '跟进人', '最后跟进日期', '归属地',
      // '创建人',
      '创建日期', '下次回访日期'],
      dispS: [
      //已共享客户
      '序号', '置顶', '客户名称', '电话号码', '学员来源', '学员级别', '跟进人', '最后跟进日期', '归属地',
      // '创建人',
      '创建日期', '下次回访日期'],
      prout: ["产品ID", "产品名称", "所属院校", "所属专业", "参考价格(元)", "产品类别", "认证类别", "产品状态", "是否需要出国", "产品管理员", "创建时间",
      // "创建人",
      '操作']
    };
  },
  watch: {
    value: function value(newV) {
      if (newV) {
        var indexCreater = newV.indexOf('创建人');
        if (indexCreater !== -1) {
          newV.splice(indexCreater, 1);
        }
      }
      this.$emit('update:displayValue', newV);
    } // dialogTableVisible(newV) {
    //     if (!newV) {
    //         console.log(123)
    //         if (this.list_type == 5) {
    //             MyLoweIndex({
    //                 page: String(this.page),
    //                 pagesize: String(this.pagesize),
    //                 list_type: 0,
    //                 field_list: this.value
    //             }).then((respomse) => {
    //                 localStorage.setItem(this.name, JSON.stringify(respomse.data.fields));
    //             });
    //         } else {
    //             MyCustomerIndex(Object.assign(this.resultList,{
    //                 page: String(this.page),
    //                 pagesize: String(this.pagesize),
    //                 list_type: this.list_type,
    //                 field_list: this.value
    //             })).then((respomse) => {
    //                 localStorage.setItem(this.name, JSON.stringify(respomse.data.fields));
    //             });
    //         }
    //     }
    // }
  },
  created: function created() {},
  mounted: function mounted() {
    this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
  },
  methods: {
    dialogTab: function dialogTab(newV) {
      var _this = this;
      if (!newV) {
        // 产品列表
        if (this.list_type == 99) {
          getProductPageList({
            page: String(this.page),
            pagesize: String(this.pagesize),
            field_list: this.value
          }).then(function (respomse) {
            _this.dialogTableVisible = false;
            localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
          });
        } else if (this.list_type == 5) {
          MyLoweIndex({
            page: String(this.page),
            pagesize: String(this.pagesize),
            list_type: 0,
            field_list: this.value
          }).then(function (respomse) {
            localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
          });
        } else {
          MyCustomerIndex(Object.assign(this.resultList, {
            page: String(this.page),
            pagesize: String(this.pagesize),
            list_type: this.list_type,
            field_list: this.value
          })).then(function (respomse) {
            _this.dialogTableVisible = false;
            localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
          });
        }
      }
    },
    handleClose: function handleClose(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },
    reduction: function reduction() {
      if (this.list_type != 4) {
        if (this.list_type == 99) {
          // 产品列表设置
          this.value = JSON.parse(JSON.stringify(this.prout));
        } else {
          this.value = JSON.parse(JSON.stringify(this.disp));
        }
      } else {
        this.value = JSON.parse(JSON.stringify(this.dispS));
      }
    },
    showEswt: function showEswt() {
      var _this2 = this;
      this.fullscreenLoading = true;
      // 产品列表设置
      if (this.list_type == 99) {
        getProductPageList({
          page: String(this.page),
          pagesize: String(this.pagesize),
          field_list: this.value
        }).then(function (respomse) {
          _this2.value = respomse.data.fields;
          _this2.dialogTableVisible = true;
          _this2.fullscreenLoading = false;
        });
      } else if (this.list_type == 5) {
        MyLoweIndex({
          page: String(this.page),
          pagesize: String(this.pagesize),
          list_type: 0
        }).then(function (respomse) {
          _this2.value = respomse.data.fields;
          _this2.dialogTableVisible = true;
          _this2.fullscreenLoading = false;
        });
      } else {
        MyCustomerIndex(this.resultList, {
          page: String(this.page),
          pagesize: String(this.pagesize),
          list_type: this.list_type
        }).then(function (respomse) {
          if (_this2.list_type == 2) {
            respomse.data.fields.map(function (item) {
              if (item == '序号') {
                item = '分配状态';
              }
            });
          }
          _this2.value = respomse.data.fields;
          _this2.dialogTableVisible = true;
          _this2.fullscreenLoading = false;
        });
      }
    },
    // 开始拖放
    ondragstart: function ondragstart(event) {
      event.dataTransfer.setData("te", event.target.innerText); //设置拖放数据与类型，不能使用text，firefox会打开新tab
      this.draging = event.target;
    },
    ondragover: function ondragover(event) {
      event.preventDefault();
      var target = event.target;
      // 判断是不是span在进行拖动，防止将整个拖放容器拖动
      if (target.nodeName === "SPAN" && target !== this.draging) {
        if (this._index(this.draging) < this._index(target)) {
          target.parentNode.insertBefore(this.draging, target.nextSibling);
        } else {
          target.parentNode.insertBefore(this.draging, target);
        }
      }
    },
    // 拖放结束刷新列表
    ondragend: function ondragend(event) {
      var nodeData = [];
      var target = event.target;
      var nodeArr = target.parentNode.children;
      // 获取排序后的数据
      if (target.nodeName === "SPAN") {
        for (var i = 0; i < nodeArr.length; i++) {
          nodeData.push(nodeArr[i].innerText);
        }
        this.value = nodeData;
      }
    },
    // 拖放时进行排序
    _index: function _index(el) {
      var index = 0;
      if (!el || !el.parentNode) {
        return -1;
      }
      while (el && (el = el.previousElementSibling)) {
        index++;
      }
      return index;
    }
  }
};